<script>
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'checkout-pay',
  components: {
    BaseButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    payDisabled: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    function onPayButtonClicked() {
      emit('pay-button-clicked')
    }

    return {
      formatDollar,
      onPayButtonClicked,
    }
  },
}
</script>

<template>
  <base-button
    :loading="isLoading || processing"
    :disabled="payDisabled"
    class="sm:mx-5 sm:max-w-xs"
    full-width
    @click="onPayButtonClicked"
  >
    <span class="flex items-end">
      <span class="text-sm leading-5">Pay</span>
      <span class="ml-2.5 text-xl leading-6">
        {{ formatDollar(total) }}
      </span>
    </span>
  </base-button>
</template>
